<template>
  <div class="page-content">
    <Breadcrumb/>
    <SearchEngine
        v-if="isAdmin"
    />

    <router-view></router-view>

  </div>
</template>

<script>
  import Breadcrumb from '../../templates/Breadcrumb/Breadcrumb.vue'
  import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";

  export default {
    name: "SettingsModule",

    components: {
      SearchEngine,
      Breadcrumb
    },

    data() {
      return {

      }
    },

    mounted() {

      // this.checkRole()

      // if(this.$route.path.indexOf('users') === -1) {
      //   let checkRole = setInterval(() => {
      //     if (Object.prototype.hasOwnProperty.call(this.$store.getters.getCurrentUserRights, "role") &&
      //       this.$store.getters.getCurrentUserRights.role.name !== this.$store.getters.GET_DEVELOPER_ROLE
      //     ) {
      //       this.$router.push(this.$store.getters.GET_PATHS.dashboard)
      //       clearInterval(checkRole)
      //     }
      //   }, 50)
      // }

      // this.$router.beforeEach((to, from, next) => {
      //   function ifSystemPages() {
      //     return to.path.indexOf('/system/') > -1
      //   }
      //
      //   if(ifSystemPages() && to.path.indexOf('users') === -1) {
      //     if(this.$store.getters.getCurrentUserRights.role.name === 'dsf') {
      //       next()
      //     } else {
      //       next({
      //         path: '/talent/onboarding',
      //       })
      //       // location.href = this.$store.getters.GET_PATHS.dashboard
      //     }
      //   }
      // })
    },

    methods: {
      // checkRole() {
      //
      //
      // }
    },
  }
</script>

<style scoped>

</style>
